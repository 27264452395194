import "./ValorantTTKLanding.scss";
import {Link} from 'react-router-dom';
import clip_image from "./posted clip.png";
import tag_bot_image from "./tag_the_bot.png";
import bot_response_image from "./bot_response.png";

function ValorantTTKLanding() {
  return <div id="ttk-landing" className="container">
    <div className='row'>
      <div className='col mt-5 pb-5'>
        <h2>What is this bot?</h2>
        <p className="mb-4">
          Valorant TTK is an AI based discord app that analyses your clips and measures the
          most important metric on shooting games: your time-to-kill.
        </p>
        <p>It uses computer vision technology to recognize enemies on the screen and count the number of frames until you shoot them</p>
        <h2>How to use it?</h2>
        <p>
          To use the app, first add it to your server by clicking <Link to="https://discord.com/oauth2/authorize?client_id=1245170687589744731">here</Link>.
        </p>
        <p>Then post a clip of your gameplay on any of your channels.</p>

        <img src={clip_image} alt="clip posted at a channel"/>

        <p className="mt-2">Then tag the bot, the bot will then search the message history and analyse the last video posted to the channel.</p>

        <img src={tag_bot_image} alt="bot tagged at a channel"/>

        <p className="mt-2">Alternatively, you can reply to a message containing a video and tag the bot, then the bot
          will analyse that video even if it was not the last one posted on the channel.</p>

        <p className="mt-2">The bot will then process the clip and post a message with the results on the same channel.</p>

        <img src={bot_response_image} alt="bot response"/>

        <p className="mt-2">If you're confused about the results, you can watch the processed video and it will show you in the video which enemies are which number.</p>

        {/*<h2 className="mt-4">I'm not impressed, will you improve it?</h2>*/}

        {/*<p>I have many features planned for this bot such as:</p>*/}

        {/*<ul>*/}
        {/*  <li>*/}
        {/*    Improving the model so it can refer to your enemies by the names of their agents rather than an assigned target number*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    Saving results on a database, so I can generate statistics about your average TTK and standard deviation*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    Using the statistics to calculate the probability of you winning a duel against another discord user on your server*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    Measuring average crosshair distance from the location where enemies appear on screen*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    Creating a webapp where you can see charts of your results and compare it with others*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    Riot login integration so I can automatically consider your current elo and compare you with other players*/}
        {/*    from your elo as well as your next elo*/}
        {/*  </li>*/}
        {/*</ul>*/}

        {/*<p>*/}
        {/*  Unfortunately, while I am an experienced developer with know-how to do these things, developing and hosting AI*/}
        {/*  models is expensive and I can't afford to continue doing it for free.*/}
        {/*</p>*/}
        {/*<p>*/}
        {/*  I really believe the Valorant community is missing out on a bot capable of measuring the TTK and that it would*/}
        {/*  be beneficial for players who want to know where they are in their Valorant journey and compare themselves to*/}
        {/*  other players, if you want to contribute to bring this into reality you can <Link to="https://www.subscribestar.com/valorant-ttk">donate</Link> through my*/}
        {/*  subscribe star page, donations will support bot development as well as cover for hosting costs.*/}
        {/*</p>*/}
      </div>
    </div>

  </div>;
}

export default ValorantTTKLanding;
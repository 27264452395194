import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthenticationState {
  id: string | null;
  username: string | null;
  token: string | null;
}

const initialState: AuthenticationState = {
  id: null,
  username: null,
  token: null
};

const authenticationSlice = createSlice({
  name: "Authentication",
  initialState,
  reducers: {
    login(state, action: PayloadAction<AuthenticationState>){
      console.log("Executing login action");
      Object.assign(state, action.payload);
      console.log(state);
      console.log(action);
    },
    logout(state){
      console.log("Executing logout action");
      state.username = null;
      state.id = null;
      state.token = null;
    }
  }
});

export const { login, logout } = authenticationSlice.actions;
export default authenticationSlice.reducer;

import React from 'react';
import {Link} from 'react-router-dom';
import LoginStatus from './LoginStatus';
import {useAppSelector} from '../hooks';

function Navbar(){
  const authentication = useAppSelector(state => state.authentication);
  const isLoggedIn = !!authentication.token;

  return <div className='navbar navbar-expand-lg navbar-dark bg-dark'>
    <div className="container">
      <Link to="/"><img src="/logo192.png" width="40px" height="40px"/></Link>

      <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarContent'
              aria-controls='navbarContent'
              aria-expanded='false' aria-label='Toggle navigation'>
        <span className='navbar-toggler-icon'></span>
      </button>

      <div className='collapse navbar-collapse' id='navbarContent'>
        {
          isLoggedIn ?
              <>
                <ul className='navbar-nav mb-2 mb-lg-0'>
                  <li className='nav-item'><Link className='nav-link' to='home'>Dashboard</Link></li>
                </ul>
                <ul className='navbar-nav mb-2 mb-lg-0'>
                  <li className='nav-item'><Link className='nav-link' to='/admin'>Administration</Link></li>
                </ul>
                <ul className='navbar-nav mb-auto mb-lg-0'>
                  <li className='nav-item'><Link className='nav-link' to='/reports'>Reports</Link></li>
                </ul>
              </> :
            <>
              <ul className='navbar-nav mb-2 mb-lg-0'>
                <li className='nav-item'><Link className='nav-link' to='/'>Valorant TTK</Link></li>
              </ul>
              {/*<ul className='navbar-nav mb-2 mb-lg-0'>*/}
              {/*  <li className='nav-item'><Link className='nav-link' to='https://www.subscribestar.com/valorant-ttk'>Donate</Link></li>*/}
              {/*</ul>*/}
              {/*<ul className='navbar-nav mb-2 mb-lg-0'>*/}
              {/*  <li className='nav-item'><Link className='nav-link' to='/login'>Login</Link></li>*/}
              {/*</ul>*/}
              {/*<ul className='navbar-nav mb-auto mb-lg-0'>*/}
              {/*  <li className='nav-item'><Link className='nav-link' to='/register'>Register</Link></li>*/}
              {/*</ul>*/}
              {/*<ul className='navbar-nav mb-2 mb-lg-0'>*/}
              {/*  <li className='nav-item'><Link className='nav-link' to='/tos'>Terms of service</Link></li>*/}
              {/*</ul>*/}
              {/*<ul className='navbar-nav mb-auto mb-lg-0'>*/}
              {/*  <li className='nav-item'><Link className='nav-link' to='/privacy-policy'>Privacy policy</Link></li>*/}
              {/*</ul>*/}
            </>
        }


        <LoginStatus/>
      </div>
    </div>
  </div>
}

export default Navbar;
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';
import {logout} from '../authentication/authentication-slice';
import {RootState} from '../../store';
import Replay from '../../models/Replay';

interface LoginForm {
  email: string;
  password: string;
}

interface RegisterForm {
  email: string;
  username: string;
  password: string;
}

interface RegisterResponse {
  id: string,
  realm: string,
  username: string,
  email: string,
  emailVerified: true,
  verificationToken: string
}

interface LoginResponse {
  token: string;
}

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://localhost:3000",
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      //headers.set("x-api-key", KEY)
      const token = (getState() as RootState).authentication.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    }
  }),
  endpoints(builder) {
    return {
      login: builder.mutation<LoginResponse, LoginForm>({
        query(body: LoginForm){
          return {
            url: "/user/login",
            method: "POST",
            body
          };
        }
      }),
      register: builder.mutation<RegisterResponse, RegisterForm>({
        query(body: RegisterForm) {
          return {
            url: "/user/signup",
            method: "POST",
            body
          }
        }
      }),
      sendLogout: builder.mutation({
        query() {
          return {
            url: "/user/logout",
            method: "POST"
          }
        },
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          try {
            //const { data } =
            await queryFulfilled
            //console.log(data)
            dispatch(logout());
            dispatch(apiSlice.util?.resetApiState());
          } catch (err) {
            console.log(err);
          }
        }
      }),
      whoAmI: builder.mutation<string, void>({
        query(){
          return {
            url: "user/whoAmI",
            method: "GET",
            responseHandler: "text"
          }
        }
      }),
      upload: builder.mutation<void, any>({
        query(body){
          return {
            url: "replays/upload",
            method: "POST",
            body
          }
        }
      }),
      listReplays: builder.mutation<Replay[], void>({
        query(){
          return {
            url: "replays/list/",
            method: "GET",
          }
        }
      }),
      getReplayDetails: builder.mutation<Replay, number>({
        query(id) {
          return {
            url: "replays/details/" + id,
            method: "GET"
          }
        }
      }),
      deleteReplayClips: builder.mutation<void, number>({
        query(replayID) {
          return {
            url: "replays/clearClips/" + replayID,
            method: "POST"
          }
        }
      })
    }
  }
});

export const {
  useLoginMutation,
  useSendLogoutMutation,
  useRegisterMutation,
  useWhoAmIMutation,
  useUploadMutation,
  useListReplaysMutation,
  useGetReplayDetailsMutation,
  useDeleteReplayClipsMutation
} = apiSlice
import { configureStore } from '@reduxjs/toolkit';
import authenticationReducer from './features/authentication/authentication-slice';
import { apiSlice } from './features/api/ApiSlice';

export const store = configureStore({
    reducer: {
        authentication: authenticationReducer,
        [apiSlice.reducerPath]: apiSlice.reducer
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(apiSlice.middleware);
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
import "./TermsOfService.scss";
import {Link} from 'react-router-dom';

function TermsOfService() {
  return <div id='tos' className='container'>
    <div className="row">
      <div className='col mt-5 mb-5'>
        <h1>Terms of Service</h1>

        <p className="effective-at">Last updated and effective: Aug 5th, 2024</p>

        <h5 className="mt-4">Agreement to Terms</h5>
        <p>
          Please read these Terms of Service (“Terms,” “Terms of Service”) carefully
          before using Valorant TTK (the “Service”, “us,” “we,” or “our”).
        </p>

        <p>
          Your access to and use of the Service is conditioned upon your acceptance
          of and compliance with these Terms. These Terms apply to all visitors, users, and others who wish to access or
          use the Service.
        </p>

        <p>
          You agree to be bound by these Terms by accessing or using the Service.
          If you disagree with any part of the terms, you do not have permission to access the Service.
        </p>

        <h5 className="mt-4">Availability, Errors, and Inaccuracies</h5>
        <p>
          We are constantly updating product and service offerings on the Service. We may experience delays in updating
          information on the Service and in our advertising on other websites. The information on the Service may contain
          errors or inaccuracies and may not be complete or current. Products or services may be described inaccurately,
          or unavailable on the Service, and we cannot guarantee the accuracy or completeness of any information found
          on the Service.

        </p>
        <p>
          Therefore, we reserve the right to change or update information and correct errors, inaccuracies, or
          omissions at any time without prior notice.
        </p>

        <h5 className="mt-4">Termination</h5>

        <p>
          We may terminate or suspend your access to the Service immediately, without prior notice or liability, under
          our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach
          of the Terms.
        </p>

        <p>
          All provisions of the Terms which by their nature should survive termination shall survive termination,
          including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of
          liability.
        </p>

        <h5 className="mt-4">Disclaimer</h5>

        <p>
          Your use of the Service is at your sole risk. The Service is provided on an “AS IS” and “AS AVAILABLE” basis.
          The Service is provided without warranties of any kind, whether express or implied, including, but not limited
          to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of
          performance.
        </p>

        <p>
          We do not warrant that a) the Service will function uninterrupted, secure, or available at any particular
          time or location; b) any errors or defects will be corrected; c) the Service is free of viruses or other
          harmful components, or d) the results of using the Service will meet your requirements.
        </p>

        <h5 className="mt-4">Changes</h5>

        <p>
          At our sole discretion, we reserve the right to modify or replace these Terms at any time. If a revision is
          material, we will provide at least 30 days’ notice before any new terms take effect. What constitutes a
          material change will be determined at our sole discretion.
        </p>

        <p>
          By continuing to access or use our Service after any revisions become effective, you agree to be bound by the
          revised terms. You can no longer use the Service if you do not agree to the new terms.
        </p>

        {/*Contact us*/}
        {/*If you have any questions about our Terms, please contact us on our support server.*/}
      </div>
    </div>
  </div>

}

export default TermsOfService;
import {useAppDispatch, useAppSelector} from '../hooks';
import {Link, useNavigate} from 'react-router-dom';
import * as Authentication from '../features/authentication/authentication-slice';

function LoginStatus() {
  const authentication = useAppSelector(state => state.authentication);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logout_Click = () => {
    dispatch(Authentication.logout());
    navigate("/");
  };

  return authentication.username ? <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
    <li className="nav-item">
      <a className="nav-link">Hello { authentication.username }</a>
    </li>
    <li className="nav-item"><a className="nav-link" onClick={logout_Click}>Log off</a></li>
  </ul> : null;
}

export default  LoginStatus;
import React from 'react';
import './App.scss';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Home from './components/Home';
import Landing from './components/Landing';
import Navbar from './components/Navbar';
import ReplayDetails from './components/ReplayDetails';
import PrivateRoute from './components/PrivateRoute';
import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';
import ValorantTTKLanding from './components/ValorantTTKLanding';

function App() {
  return <BrowserRouter>
    <Navbar></Navbar>
    <div id="content">
      <Routes>
        <Route path='/' Component={ValorantTTKLanding} />
        {/*<Route path='/' Component={Landing} />*/}
        {/*<Route path='/login' Component={Login} />*/}
        {/*<Route path='/register' Component={Register} />*/}
        {/*<Route path='/home' element={<PrivateRoute><Home/></PrivateRoute>}></Route>*/}
        {/*<Route path='/details/:id' element={<PrivateRoute><ReplayDetails/></PrivateRoute>}></Route>*/}
        <Route path='/tos' Component={TermsOfService} />
        <Route path='/privacy-policy' Component={PrivacyPolicy} />
      </Routes>
    </div>
  </BrowserRouter>;
}


export default App;

import "./TermsOfService.scss";
import {Link} from 'react-router-dom';

function PrivacyPolicy() {
  return <div id='tos' className='container'>
    <div className="row">
      <div className='col mt-5 mb-5'>
        <h1>Privacy Policy</h1>

        <p className="effective-at">Last updated and effective: Aug 5th, 2024</p>

        <h5 className="mt-4">Collection of data</h5>
        <p>
          As an AI based bot, Valorant TTK reserves the right to collect and process gameplay data for the purposes
          of training the bot as well as providing the services offered, only images that caused the AI model to
          perform poorly are saved for training purposes and those are not associated to your username.
        </p>

        <p>
          Calculated gameplay statistics may be stored in association to your username even though the original video
          is deleted right after it's processed and sent back to the user.
        </p>

        <p>
          User IDs and calculated gameplay statistics are the only data stored by this bot.
        </p>

        <h5 className="mt-4">Security of data</h5>

        <p>
          We only retain collected information for as long as necessary to provide you with your requested service.
          What data we do collect, we’ll protect using commercially reasonable means to prevent loss and theft,
          as well as unauthorized access, disclosure, copying, use, or modification.
        </p>

        <h5 className="mt-4">Opting out</h5>

        <p>
          Users can always opt-out simply by stopping using Valorant TTK or kicking it from their server. Upon request,
          users may also request to have all of their current data deleted from Valorant TTK.
        </p>

        {/*Contact us*/}
        {/*If you have any questions about our Terms, please contact us on our support server.*/}
      </div>
    </div>
  </div>

}

export default PrivacyPolicy;